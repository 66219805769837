.hold-breath-block {
  .hold-breath-video {
    video {
      position: fixed;
      right: 0;
      bottom: 0;
      min-width: 100%;
      min-height: 100%;
      width: initial !important;
      height: initial !important;
      //transform: translateY(-50%);
      top: 50%;


      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
