/* Typography */

$font-fallback: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica", " Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
$font-primary: 'Cuprum', $font-fallback;
$font-secondary: 'Cuprum', $font-fallback;

/* Default text properties */
$font-size-default: 17px;
$line-height-default: 20px;

/* Colors */

$color-white: #ffffff;
$color-white-hover: #BDD2F3;
$color-brand-blue: #2D68C4;
$color-yellow: #fdb913;
$color-yellow-dark: #6d5416;
$color-black: #000000;
$color-gray-darkest: #181818;
$color-gray: #666666;
$color-gray-light: #c4c4c4;