.tube {
	$this: &;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: contain;
	left: 0;
	position: absolute;
	padding-top: 100%;
	top: 0;
	width: 100%;
	&--type-straight {
		background-image: url(../../assets/images/tube-straight.svg);
	}
	&--type-bend {
		background-image: url(../../assets/images/tube-bend.svg);
	}

	&--type-straight#{$this}--active {
		background-image: url(../../assets/images/tube-straight-filled.svg);

	}
	&--type-bend#{$this}--active {
		background-image: url(../../assets/images/tube-bend-filled.svg);

	}

	&--shift-1 {
		transform: rotate(90deg);
	}
	&--shift-2 {
		transform: rotate(180deg);
	}
	&--shift-3 {
		transform: rotate(270deg);
	}
}