.plain-step {
	background: $color-white;
	min-height: 100vh;
	min-height: calc(100 * var(--vh));
	position: relative;
	box-sizing: border-box;

	* > {
		box-sizing: border-box;
	}

	@include media-breakpoint-up(md) {
		padding-bottom: 80px;
		padding-top: 160px;
	}
	@include media-breakpoint-only(sm) {
		padding-bottom: 110px;
		padding-top: 130px;
	}
	@include media-breakpoint-down(xs) {
		padding-bottom: 40px;
		padding-top: 70px;
	}
	&__container {
		margin-left: auto;
		margin-right: auto;
		@include media-breakpoint-up(md) {
			max-width: 440px;
		}
		&--flex {
			@include media-breakpoint-up(md) {
				max-width: 670px;
			}
			@include media-breakpoint-down(sm) {
				//margin-top: 320px;
			}
		}
		@include media-breakpoint-only(sm) {
			padding-left: 45px;
			padding-right: 45px;
		}
		@include media-breakpoint-down(xs) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	&__title {
		color: $color-brand-blue;
		font-family: $font-secondary;
		font-size: 36px;
		line-height: 1;
		margin-bottom: 40px;
	}
	&__subtitle {
		color: $color-black;
		display: block;
		font-size: 17px;
		line-height: 20px;
		margin-bottom: 10px;
	}
	&__button {
		margin-bottom: 20px;
		margin-top: 20px;
	}
	&__field {
		margin-bottom: 20px;
	}
	&__step-nav {
		position: absolute;
		z-index: 2;
		bottom: 20px;
		@include media-breakpoint-up(md) {
			left: 50%;
			transform: translateX(-50%);
			width: 810px;
		}
		@include media-breakpoint-only(sm) {
			left: 45px;
			right: 45px;
		}
		@include media-breakpoint-down(xs) {
			left: 15px;
			right: 15px;
		}
	}
	&__card-picker {
		margin-top: 20px;
		//@include media-breakpoint-down(xs) {
		//	margin-left: -20px;
		//	margin-right: -20px;
		//}
	}

	&__video-wrapper {
		margin-bottom: 20px;
		margin-top: 30px;
		padding-top: 56.25%;
		position: relative;
	}

	&__video-player {
		left: 0;
		position: absolute;
		top: 0;
	}

	&__panel-picker {
		margin-bottom: 30px;
	}

	&--constitution &__container {
		@include media-breakpoint-up(md) {
			max-width: 670px;
		}
	}
}
