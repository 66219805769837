.dots {
	&__list {
		display: flex;
	}
	&__item {
		background: $color-gray-light;
		height: 10px;
		transition: .2s;
		width: 10px;
		&:nth-child(n + 2) {
			margin-left: 20px;
		}
		&--done {
			background: $color-brand-blue;
		}
	}
}