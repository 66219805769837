.card-picker {
  &__list {
    display: flex;
  }

  &__item {
    background: $color-brand-blue;
    cursor: pointer;
    flex-grow: 1;
    position: relative;
    border-radius: 4px;
    color: $color-white;

    &::before {
      content: "";
      display: block;
      border-radius: 2px;

      border: 1px solid $color-white;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      position: absolute;
      left: 2px;
      top: 2px;
      box-sizing: border-box;
      pointer-events: none;
    }

    @include media-breakpoint-up(sm) {
      height: 280px;
    }
    @include media-breakpoint-down(xs) {
      height: 177px;
    }

    &:nth-child(n + 2) {
      @include media-breakpoint-up(sm) {
        margin-left: 20px;
      }
      @include media-breakpoint-down(xs) {
        margin-left: 8px;
      }
    }
  }

  &--flex {
    margin-top: 40px;

    .card-picker__item {
      @include media-breakpoint-up(sm) {
        height: 336px;
      }
      @include media-breakpoint-down(xs) {
        height: 186px;
      }

      &:nth-child(n + 2) {
        @include media-breakpoint-down(xs) {
          margin-left: 0;
        }
      }
      @include media-breakpoint-down(xs) {
        & + .card-picker__item {
          margin-top: 20px;
        }
      }
    }

    .card-picker__list {
      @include media-breakpoint-down(xs) {
        flex-direction: column;
      }
    }
  }

  &__title {
    bottom: 20px;
    font-size: 17px;
    line-height: 20px;
    position: absolute;
    text-align: center;
    width: 100%
  }

  &__image {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    @include media-breakpoint-up(sm) {
      bottom: 50px;
      top: 20px;
    }
    @include media-breakpoint-down(xs) {
      bottom: 50px;
      top: 20px;
    }
  }

  &__image-2 {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    @include media-breakpoint-up(sm) {
      bottom: 50px;
      top: 20px;
    }
    @include media-breakpoint-down(xs) {
      bottom: 50px;
      top: 20px;
    }
  }

  &--constitution &__item--1 &__image {
    background-image: url(../../assets/images/body-ectomorph.png);
  }

  &--constitution &__item--2 &__image {
    background-image: url(../../assets/images/body-mezomorph.png);
  }

  &--constitution &__item--3 &__image {
    background-image: url(../../assets/images/body-endomorph.png);
  }


  &--flex &__item--1 &__image {
    background-image: url(../../assets/images/flex/flex_3_1.png);
  }

  &--flex &__item--2 &__image {
    background-image: url(../../assets/images/flex/flex_2_1.png);
  }

  &--flex &__item--3 &__image {
    background-image: url(../../assets/images/flex/flex_1_1.png);
  }


  &--flex &__item--1 &__image-2 {
    background-image: url(../../assets/images/flex/flex_3_2.png);
  }

  &--flex &__item--2 &__image-2 {
    background-image: url(../../assets/images/flex/flex_2_2.png);
  }

  &--flex &__item--3 &__image-2 {
    background-image: url(../../assets/images/flex/flex_1_2.png);
  }

  &--flex &__image {
    height: 140px;
    min-width: 190px;
    top: 10px;
    left: 0;

    @include media-breakpoint-down(xs) {
      width: 50%;
      min-width: 140px;
      max-width: 140px;
    }
  }

  &--flex &__image-2 {
    min-width: 190px;
    height: 140px;
    top: 140px;
    right: 0;

    @include media-breakpoint-down(xs) {
      width: 50%;
      top: 10px;
      left: initial;
      min-width: 140px;
      max-width: 140px;
    }
  }
}
