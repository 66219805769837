.button {
	display: flex;
	align-items: center;
	justify-content: center;
	color: $color-white;
	cursor: pointer;
	font-size: 20px;
	font-family: $font-primary;
	line-height: 42px;
	text-align: center;
	width: 100%;
	border-radius: 4px;

	transition: 0.2s all ease-in-out;

	img {
		padding: 0 10px;
	}

	&--outline {
		background: transparent;
		border: 2px solid $color-brand-blue;
		color: $color-brand-blue;
		&:disabled {
			border-color: $color-gray-light;
			color: $color-gray-light;
		}
	}

	&--solid {
		position: relative;
		background: $color-brand-blue;
		border: 2px solid $color-brand-blue;
		&:disabled {
			background: $color-gray-light;
			border-color: $color-gray-light;
		}

		&::before {
			content: "";
			display: block;
			border-radius: 2px;
			border: 1px solid $color-white;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			box-sizing: border-box;
			pointer-events: none;
		}
	}

	.loader {
		line-height: 42px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 42px;
	}
}
