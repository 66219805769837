.app {
	height: 100vh;
	height: calc(100 * var(--vh));

	&--step-jump {
		background: $color-white;
		box-sizing: border-box;

		&--center {
			justify-content: center;
		}

		height: auto;
		width: 100%;
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		padding-top: 80px;

		.jump-content {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			max-width: 440px;
			width: 100%;
			margin: 0 auto;
			margin-bottom: 40px;
			margin-top: 50px;
			box-sizing: border-box;
			color: $color-black;

			@media (max-width: 769px) {
				margin-top: 0;
			}

			@media (max-width: 600px) {
				padding: 0 15px;
			}

			&__title {
				font-family: $font-secondary;
				font-style: normal;
				font-weight: 500;
				font-size: 36px;
				color: $color-brand-blue;
				position: relative;
				margin-bottom: 40px;

				@media (max-width: 500px) {
					margin-top: 0;
				}
			}

			&__text {
				font-style: normal;
				font-weight: normal;
				font-size: 17px;
				line-height: 20px;
				font-feature-settings: 'kern' off;
				color: $color-black;
			}

			&__video {
				width: 100%;
				margin-bottom: 40px;
				margin-top: 40px;
				padding-top: 56.25%;
				position: relative;
				max-width: 630px;
			}

			&__player {
				left: 0;
				position: absolute;
				top: 0;
			}

			&__input-wrapper {
				width: 100%;
				margin-top: 20px;
				margin-bottom: 20px;
			}

			&__button-wrapper {
				width: 100%;
				margin-bottom: 40px;

				.button {
					width: 100%;
				}
			}
		}
	}

	&--fullscreen-loading {
		color: $color-white;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $color-gray-darkest;
	}

	&--step-elasticity {
		height: auto;
		color: $color-black;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $color-white;
	}

	&--step-fox-hello {
		color: $color-white;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $color-gray-darkest;
		min-height: 880px;

		@include media-breakpoint-up(md) {
			min-height: initial;
		}

		@include media-breakpoint-down(sm) {
			min-height: initial;
		}

		.fox-gym {
			background-image: url(../../assets/images/zal-bg.png);
			background-repeat: no-repeat;
			background-size: cover;
			background-position: top center;
			position: absolute;
			width: 100%;
			height: 70%;
			top: 30%
		}

		.step__playground {
			width: 100%;
			height: 100%;
		}

		.plain-step {
			background: none;
			padding: 0;
			position: relative;
			width: 100%;
			height: 100%;
			padding-top: 40px;
			box-sizing: border-box;

			.plain-step__container {
				max-width: initial;
			}

			.fox-hello-popup {
				position: absolute;
				width: 100%;
				top: 0;
				left: 0;
				height: 50%;
				@media (max-height: 415px) {
					height: 70%;
				}

				&::after {
					content: '';
					position: absolute;
					background-color: $color-white;
					background-repeat: no-repeat;
					background-position: bottom center;
					background-size: 100%;
					top: 0;
					left: 0;
					width: 100%;
					height: calc(100% - 200px);
				}

				@include media-breakpoint-down(xs) {
					&::after {
						height: calc(100% - 40px);
					}
				}

				&::before {
					content: '';
					position: absolute;
					background-image: url(./../../assets/images/popup.svg);
					background-repeat: no-repeat;
					background-position: bottom center;
					background-size: 100%;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}

				.content {
					margin: 0 auto;
					max-width: 440px;
					padding-top: 120px;
					position: relative;
					z-index: 10;
					padding-bottom: 20px;

					@include media-breakpoint-down(lg) {
						padding: 0 15px;
						padding-top: 50px;
						height: 100%;
						display: flex;
						flex-direction: column;
						padding-bottom: 60px;
						justify-content: center;
						box-sizing: border-box;
					}

					h2 {
						font-family: $font-secondary;
						font-style: normal;
						font-weight: 500;
						font-size: 36px;
						color: $color-brand-blue;
						position: relative;
						@media (max-width: 824px) {
							margin: 0px;
						}
					}


					p {
						color: $color-black;
					}
				}
			}
			.button {
				width: 100%;
				@media (max-height: 415px){
					width:	70%
				}
			}

			.big-fox {
				background: url(./../../assets/images/big_wolf.png) no-repeat;
				//background-size: contain;
				width: 212px;
				height: 420px;
				position: absolute;
				bottom: -20px;
				right: 0;


				@include media-breakpoint-down(sm){
					width: 170px;
					height: 300px;
				}

			}
		}
	}

	&--step-final {
		color: $color-black;
		display: flex;
		height: auto;
		background-color: $color-gray-darkest;
	}

	.payment-success-content {
		max-width: 440px;
		width: 100%;
		margin: 0 auto;
		margin-bottom: 40px;
		margin-top: 50px;
		box-sizing: border-box;
		color: $color-black;

		@media (max-width: 500px) {
			margin-top: 0;
		}

		@media (max-width: 600px) {
			padding: 0 15px;
		}

		h2 {
			padding-left: 96px;
			height: 80px;
			line-height: 73px;
			font-family: $font-secondary;
			font-style: normal;
			font-weight: 500;
			font-size: 36px;
			color: $color-brand-blue;
			position: relative;
			margin-bottom: 40px;

			@media (max-width: 500px) {
				margin-top: 0;
			}

			&::before {
				content: '';
				position: absolute;
				display: block;
				width: 80px;
				height: 80px;
				background: url('./../../assets/images/icon_check_yellow.svg') no-repeat;
				top: 0;
				left: 0;
			}
		}

		p {
			font-style: normal;
			font-weight: normal;
			font-size: 17px;
			line-height: 21px;
			font-feature-settings: 'kern' off;
			color: $color-black;

			&.yellow {
				color: $color-brand-blue;
			}
		}

		.start-game-wrapper {
			margin-top: 40px;
			.button {
				width: 210px;

				@media (max-width: 500px) {
					width: 100%;
				}
			}
		}
	}

	.finish-page-content {
		max-width: 440px;
		width: 100%;
		margin: 0 auto;
		margin-bottom: 40px;
		margin-top: 40px;
		box-sizing: border-box;

		@media (max-width: 1000px) {
			padding: 0 30px;
		}

		@media (max-width: 600px) {
			padding: 0 15px;
		}

		.we-send {
			font-style: normal;
			font-weight: bold;
			font-size: 12px;
			line-height: 14px;
			font-feature-settings: 'kern' off;
			color: $color-gray;
			margin-top: 40px;
			margin-bottom: 40px;
		}

		strong {
			font-style: normal;
			font-weight: normal;
			font-size: 24px;
			line-height: 28px;
			color: $color-brand-blue;
		}

		p {
			padding: 0;
			margin: 0;
			margin-bottom: 40px;
		}

		.plain-step__video-wrapper {
			margin-top: 40px;
			margin-bottom: 40px;
		}
	}

	.lk-content {
		max-width: 900px;
		width: 100%;
		margin: 0 auto;
		margin-bottom: 40px;
		box-sizing: border-box;

		@media (max-width: 1000px) {
			padding: 0 30px;
		}

		@media (max-width: 600px) {
			padding: 0 15px;
		}

		&__start_new {
			margin-top: 40px;
			.button {
				width: 206px;
			}
		}

		.lk-content__title {
			margin-bottom: 20px;
		}

		&__un-finished {
			.lk-content__title {
				color: $color-black;
			}
		}

		&__finished {
			.lk-content__title {
				color: $color-black;
			}
		}

		&__un-finished + .lk-content__finished {
			margin-top: 40px;
		}
	}

	.lk-header-wrapper {
		border-bottom: 1px solid $color-gray-light;

		position: fixed;
		top: 0;
		width: 100%;
		z-index: 2;

		.header {
			box-sizing: border-box;
			padding: 0 40px;
			height: 60px;
			& * {
				box-sizing: border-box;
			}

			width: 100%;
			display: flex;
			@media (max-width: 768px) {
				padding: 0 20px;
			}

			&__logo {
				background-image: url(../../assets/images/logo.svg);
				background-repeat: no-repeat;
				background-size: contain;
				width: 115px;
				height: 30px;
				display: inline-block;
			}

			.left {
				flex: 1;
				width: auto;
				display: flex;
				align-items: center;

				.header__logo {
					margin-right: 20px;
				}

				@media (max-width: 1200px) {
					display: flex;
					align-items: center;
					justify-content: flex-start;
				}
			}

			.right {
				display: flex;
				justify-content: flex-end;
				align-items: center;

				.burger {
					display: none;
					background: url('./../../assets/images/burger.svg') no-repeat;
					width: 40px;
					height: 40px;
					margin-left: 10px;
					@media (max-width: 768px) {
						display: flex;
					}
				}
			}
		}

		.menu {
			display: flex;
			align-items: center;

			&--person {
				position: fixed;
				top: 60px;
				right: 15px;
				flex-direction: column;
				background: $color-brand-blue;

				visibility: hidden;
				opacity: 0;
				transition: opacity 0.2s ease-in-out;

				&.menu--show-modal {
					visibility: visible;
					opacity: 1;
				}

				.menu__item {
					cursor: pointer;
					padding: 10px 20px;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					width: 140px;
					border-bottom: 1px solid $color-white;

					@media (max-width: 500px) {
						width: 290px;
					}

					&:first-child {
						@media (min-width: 1200px) {
							padding-left: 20px;
						}
					}
				}
			}

			@media (max-width: 768px) {
				position: fixed;
				top: 60px;
				right: 15px;
				flex-direction: column;
				background: $color-black;

				visibility: hidden;
				opacity: 0;
				transition: opacity 0.2s ease-in-out;

				&--show-modal {
					visibility: visible;
					opacity: 1;
				}
			}

			@media (max-width: 500px) {
				width: 290px;
				left: 50%;
				transform: translate(-50%, 0);
			}

			&__item {
				img {
					margin-right: 5px;
				}

				&:first-child {
					@media (min-width: 1200px) {
						padding-left: 0;
					}
				}

				padding: 20px;
				text-decoration: none;
				font-size: 17px;
				line-height: 20px;
				color: $color-white;
				transition: color 0.2s ease-in-out;

				&:hover {
					color: $color-white-hover;
				}

				&:active, &--active {
					font-weight: bold;
				}

				@media (max-width: 768px) {
					padding: 10px;
					width: 200px;
					font-size: 17px;
					line-height: 20px;
					color: $color-white;
					border-bottom: 1px solid $color-white;
				}

				@media (max-width: 500px) {
					width: 290px;
				}

				&--disabled {
					cursor: default;
					color: $color-gray-light;

					&:hover {
						color: $color-gray-light;
					}
				}
			}
		}
	}

	&--system {
		background: $color-white;
		box-sizing: border-box;

		&--center {
			justify-content: center;
		}

		height: auto;
		width: 100%;
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		padding-top: 80px;
	}

	&__logo {
		background-image: url(../../assets/images/logo.svg);
		background-repeat: no-repeat;
		background-size: contain;
		height: 80px;
		position: absolute;
		width: 90px;
		@include media-breakpoint-up(md) {
			height: 80px;
			left: 40px;
			top: 40px;
			width: 90px;
		}
		@include media-breakpoint-only(sm) {
			height: 80px;
			left: 30px;
			top: 30px;
			width: 90px;
		}
		@include media-breakpoint-down(xs) {
			height: 30px;
			left: 15px;
			top: 15px;
			width: 34px;
		}
	}
	&__step-target {
		position: absolute;
		@include media-breakpoint-up(md) {
			right: 40px;
			top: 40px;
		}
		@include media-breakpoint-only(sm) {
			right: 30px;
			top: 30px;
		}
		@include media-breakpoint-down(xs) {
			right: 15px;
			top: 15px;
		}
	}

	&--step-gender &__step-target {

	}

	&--step-reaction &__step-target,
	&--step-cns &__step-targe {
		@include media-breakpoint-up(md) {
			right: 500px;
		}
	}

	&--step-age {
		.plain-step {
			&__container {
				@include media-breakpoint-up(md) {
					max-width: 670px;
				}
				//@include media-breakpoint-only(sm) {
				//	padding-left: 45px;
				//	padding-right: 45px;
				//}
				//@include media-breakpoint-down(xs) {
				//	padding-left: 40px;
				//	padding-right: 40px;
				//}
			}
		}

		.age-buttons {
			@include media-breakpoint-up(sm) {
				display: flex;
				border-bottom: 1px solid $color-gray-light;
				padding-bottom: 40px;

				.button {
					width: 118px;
					height: 80px;
				}

				.plain-step__button + .plain-step__button {
					margin-left: 20px;
				}
			}

			@include media-breakpoint-down(sm) {
				.button {
					height: 50px;
				}

				border-bottom: 1px solid $color-gray-light;
				padding-bottom: 20px;
			}
		}

		.no-age {
			font-family: $font-primary;
			color: $color-black;

			.input-group + .input-group {
				margin-top: 20px;
			}

			h2 {
				font-style: normal;
				font-weight: normal;
				font-size: 24px;
				line-height: 28px;
				margin: 40px 0 0;
				padding: 0;
			}


			p {
				font-size: 17px;
				line-height: 20px;
				font-feature-settings: 'kern' off;
				max-width: 440px;
				padding: 20px 0;
				margin: 0;
			}

			i.policy {
				display: block;
				margin-top: 20px;
				font-style: normal;
				font-weight: bold;
				font-size: 12px;
				line-height: 14px;
				font-feature-settings: 'kern' off;
				color: $color-gray-light;

				a {
					color: $color-gray-light;
					text-decoration: none;
				}
			}

			.feedback {
				.button {
					margin-top: 10px;
					width: 210px;

					@include media-breakpoint-down(xs) {
						width: 100%;
						max-width: 400px;
					}
				}
			}
		}
	}


	.input-group.app--input {
		width: 100%;
		max-width: initial;
		input {
			width: 100%;
			max-width: initial;
		}
	}

	.input-group {
		position: relative;
		label {
			position: absolute;
			left: 10px;
			top: 0;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;

			font-style: normal;
			font-weight: normal;
			font-size: 20px;
			line-height: 50px;
			color: #181818;
		}

		input {
			background: #F0F0F0;
			height: 50px;
			max-width: 440px;
			color: $color-gray-darkest;
			border: none;
			border-radius: 4px;
			box-sizing: border-box;
			width: 100%;
			//margin-bottom: 10px;
			padding-left: 110px;

			font-style: normal;
			font-weight: bold;
			font-size: 20px;
			line-height: 50px;
		}

		&--white {
			input {
				background: $color-white;
				border: 1px solid $color-gray-light;
			}
		}
	}

	.input-group + .input-group {
		margin-top: 10px;
	}

	.login-form {
		max-width: 440px;
		width: 100%;
		margin: 0 auto;
		box-sizing: border-box;
		.input-group + .input-group {
			margin-top: 20px;
		}

		@include media-breakpoint-down(xs) {
			padding: 0 15px;
		}
	}

	.tabs {
		color: $color-black;
		margin-top: 40px;

		.tabs-wrapper {
			width: 100%;
			display: flex;
			color: $color-gray;
			.tab {
				flex: 1;
				cursor: pointer;
				font-family: $font-primary;
				font-style: normal;
				font-weight: normal;
				font-size: 24px;
				line-height: 28px;
				border-bottom: 1px solid $color-brand-blue;
				padding-bottom: 20px;
				@media (max-width: 768px) {
					font-size: 17px;
					line-height: 20px;
				}


				& + .tab {
					padding-left: 20px;
				}

				&--active {
					color: $color-brand-blue;
					border-bottom: 3px solid $color-brand-blue;
				}
			}
		}

		.tabs-content-wrapper {
			.tab-content {
				display: none;
				&--active {
					display: block;
				}
			}
		}
	}

	i.policy {
		display: block;
		margin-top: 20px;
		font-style: normal;
		font-weight: bold;
		font-size: 12px;
		line-height: 14px;
		font-feature-settings: 'kern' off;
		color: $color-gray-light;

		a {
			color: $color-brand-blue;
			text-decoration: none;
		}
	}

	.big-loader {
		width: 100%;
		height: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.payment-page-content {
		max-width: 440px;
		width: 100%;
		margin: 0 auto;
		margin-bottom: 40px;
		color: $color-black;
		margin-top: 80px;

		@media (max-width: 500px) {
			padding-left: 15px;
			padding-right: 15px;
			width: auto;
			margin-top: 0;
		}

		.you-login-as {
			font-size: 17px;
			line-height: 20px;
			font-feature-settings: 'kern' off;
			color: $color-brand-blue;
			margin-bottom: 36px;
		}

		.plain-step__title {
			margin-bottom: 46px;
		}

		.line {
			position: relative;
			padding: 0;
			margin: 0;
			color: $color-black;
			font-style: normal;
			font-weight: normal;
			margin-bottom: 18px;
			font-size: 17px;
			line-height: 20px;

			&--padding {
				padding-left: 30px;
			}

			&--icon {
				&::before {
					content: '';
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					width: 20px;
					height: 20px;
					background: url(../../assets/images/icon_check.svg) no-repeat;
				}
			}

			&--first {
				font-size: 24px;
				line-height: 28px;
				&::before {
					top: 3px;
				}
			}

			&--mb-10 {
				margin-bottom: 10px;
			}
		}

		.price-block {
			display: flex;
			justify-content: space-between;
			margin-top: 22px;
			margin-bottom: 40px;

			&__title {
				font-style: normal;
				font-weight: normal;
				font-size: 24px;
				line-height: 28px;
				color: $color-black;
			}
			&__value {
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 28px;
				text-align: right;
				color: $color-black;
			}
		}

		.buy-new-game-wrapper {
			.button {
				width: 210px;
				@media (max-width: 500px) {
					width: 100%;
				}
			}
		}

	}
}
