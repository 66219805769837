.step-nav {
	&__list {
		display: flex;
		align-items: baseline;
	}
	&__item {
		background: $color-gray-light;
		flex-grow: 1;
		height: 4px;
		&:nth-child(n + 2) {
			@include media-breakpoint-up(md) {
				margin-left: 25px;
			}
			@include media-breakpoint-only(sm) {
				margin-left: 15px;
			}
			@include media-breakpoint-down(xs) {
				margin-left: 7px;
			}
		}

		&--passed {
			background: $color-brand-blue;
		}

		&--active {
			background: $color-brand-blue;
			height: 8px;
		}
	}
}
