@import './variables/index';
@import './fonts';

body {
  margin: 0;
  font-family: $font-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import './mixins/bs4-like-media';

@import './blocks/app';
@import './landing';
@import './blocks/button';
@import './blocks/card-picker';
@import './blocks/dots';
@import './blocks/field';
@import './blocks/haregame';
@import './blocks/harehole';
@import './blocks/input';
@import './blocks/panel';
@import './blocks/panel-picker';
@import './blocks/plain-step';
@import './blocks/plumber';
@import './blocks/step';
@import './blocks/step-nav';
@import './blocks/step-target';
@import './blocks/tube';
@import './blocks/medoed';
@import './blocks/hold-breath';
@import './blocks/countdown';
@import './blocks/lk-test-row';
