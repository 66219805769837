/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.landing-root {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  & * {
    box-sizing: border-box;
  }

  .landingPage-wrapper {
    width: 100%;
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;

    &--header-padding {
      padding-top: 60px;

      @media (max-width: 768px) {
        padding-top: 0;
      }

      .landingPage {
        position: relative;
      }
    }

    &.bg-wolf {
      background: url('./../assets/images/wolf-half.png') no-repeat;
      background-position: right bottom;

      @media (max-width: 1300px) {
        background-size: 210px 208px;
      }

      @media (max-width: 680px) {
        padding-bottom: 200px;
        background-position: center bottom;
      }
    }

    .player-wrapper {
      position: relative;
      padding-top: 56.25%; /* 720 / 1280 = 0.5625 */

      .landing_video {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0 auto;
        width: 100% !important;
        height: 100% !important;

        .react-player__preview {
          @media (max-width: 1000px) {
            img {
              width: 100px;
              height: 100px;
            }
          }

          @media (max-width: 500px) {
            img {
              width: 50px;
              height: 50px;
            }
          }
        }
      }
    }

    .landing-button {
      text-decoration: none;
      width: 325px;
      height: 50px;
      display: flex;
      justify-content: center;
      cursor: pointer;
      background-color: transparent;
      border: 2px solid $color-brand-blue;
      color: $color-white;
      line-height: 50px;
      align-items: baseline;
      border-radius: 4px;
      user-select: none;

      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      text-align: center;

      a {
        text-decoration: none;
        color: inherit;
        display: block;
        width: 100%;
      }

      @media (max-width: 500px) {
        width: 100%;
      }

      img {
        padding: 0 10px;
      }

      &--blue {
        position: relative;
        color: $color-white;
        background-color: $color-brand-blue;

        &::before {
          content: "";
          display: block;
          border-radius: 2px;
          border: 1px solid $color-white;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          box-sizing: border-box;
          pointer-events: none;
        }
      }

      &--width-210 {
        width: 210px;
      }
    }

    &--white {
      background-color: transparent;
    }

    .landingPage {
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      background-color: $color-white;

      h2 {
        padding: 0;
        margin: 0;
        font-family: $font-secondary;
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 36px;
        color: $color-brand-blue;
      }

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        font-feature-settings: 'kern' off;
        color: $color-black;

        &.black {
          color: $color-black;
        }
      }

      &--white {
        background-color: transparent;

        p {
          color: $color-gray;
        }
      }


      .header-wrapper {
        padding: 0 40px;
        background: $color-brand-blue;

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        //max-width: 1440px;
        z-index: 2;

        @media (max-width: 768px) {
          position: static;
        }

        @media (max-width: 768px) {
          padding: 0 15px;
        }

        .header {
          width: 100%;
          display: flex;

          .left {
            flex: 1;
            width: auto;

            @media (max-width: 1200px) {
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }

            .burger {
              display: none;
              background: url('./../assets/images/burger.svg') no-repeat;
              width: 40px;
              height: 40px;
              margin-left: 10px;
              @media (max-width: 1200px) {
                display: flex;
              }
            }

            .menu {
              display: flex;

              @media (max-width: 1200px) {
                position: fixed;
                top: 50px;
                left: 50px;
                flex-direction: column;
                background: $color-brand-blue;

                visibility: hidden;
                opacity: 0;
                transition: opacity 0.2s ease-in-out;

                &--show-modal {
                  visibility: visible;
                  opacity: 1;
                }
              }

              @media (max-width: 768px) {
                position: absolute;
              }

              @media (max-width: 500px) {
                width: 290px;
                left: 50%;
                transform: translate(-50%, 0);
              }

              &__item {
                &:first-child {
                  @media (min-width: 1200px) {
                    padding-left: 0;
                  }
                }

                padding: 20px;
                text-decoration: none;
                font-size: 17px;
                line-height: 20px;
                color: $color-white;
                transition: color 0.2s ease-in-out;

                &:hover {
                  color: #BDD2F3;
                }

                &:active, &--active {
                  font-weight: bold;
                }

                @media (max-width: 1200px) {
                  padding: 10px;
                  width: 200px;
                  font-size: 17px;
                  line-height: 20px;
                  color: $color-white;
                  border-bottom: 1px solid $color-white;
                }

                @media (max-width: 500px) {
                  width: 290px;
                }
              }
            }
          }

          .right {
            width: 180px;
            display: flex;
            justify-content: flex-end;

            .enter-lk {
              padding: 20px;
              text-decoration: none;
              font-size: 17px;
              line-height: 20px;
              color: $color-white;
              transition: color 0.2s ease-in-out;

              @media (max-width: 1200px) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 23px;
                text-align: center;
              }

              &:hover {
                color: #BDD2F3;
              }

              &:active {
                font-weight: bold;
              }
            }
          }
        }
      }

      .img-first-step {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-gray-darkest;
        height: auto;
        max-height: 240px;
        overflow: hidden;

        @media (max-width: 768px) {
          img {
            height: calc(100vw / 3);
          }
        }
      }

      .block-wrapper {
        padding: 0 40px;
        border-bottom: 1px solid $color-gray-light;

        @media (max-width: 768px) {
          padding: 0 15px;
        }

        &--no-border {
          border-bottom: none;
        }

        .block {
          padding: 60px 0;
          margin: 0 auto;
          width: 100%;
          max-width: 670px;
        }
      }

      .block-1 {
        .landing_video {
          padding: 30px 0;
          padding-bottom: 6px;
        }
      }

      .block-2 {
        padding-bottom: 0 !important;
        .trainer-block {
          .trainer-item {
            display: flex;
            padding-bottom: 45px;

            @media (max-width: 500px) {
             flex-direction: column;
            }

            .left {
              img {
                padding-right: 40px;

                @media (max-width: 500px) {
                  width: 80px;
                  height: 80px;
                  padding: 0;
                  margin-bottom: 20px;
                }
              }
            }

            .right {
              h4 {
                color: $color-brand-blue;
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 28px;
                padding: 0;
                margin: 0;
              }

              p {
                b {
                  color: $color-black;
                  font-style: normal;
                  font-weight: bold;
                  font-size: 17px;
                  line-height: 20px;
                  font-feature-settings: 'kern' off;
                }
              }
            }
          }
        }
      }

      .block-before-steps {
        padding-top: 0;
        padding-bottom: 30px;
      }

      .block-3 {
        .landing-button {
          margin: 60px auto 0;
        }

        h2 {
          @media (max-width: 768px) {
            br {
              display: none;
            }
          }
        }
      }


      .block-sportsman {
        padding: 60px 0;
        padding-bottom: 30px;

        h2 {
          width: 100%;
          max-width: 670px;
          margin: 0 auto;
        }
      }

      .block-sportsman--flex {
        display: flex;
        justify-content: space-around;
        padding: 0 20px ;

        @media (max-width: 1200px) {
          flex-flow: row wrap;
          justify-content: space-around;
          &::after {
            content: "";
            flex: auto;
            max-width: 256px;
          }
        }

        @media (max-width: 600px) {
          flex-direction: column;
        }

        //@media (max-width: 600px) {
        //  &::after {
        //    display: none;
        //  }
        //}

        .sportsman-item {
          width: 256px;

          @media (max-width: 600px) {
            width: 100%;
          }

          & + .sportsman-item {
            margin-left: 40px;

            @media (max-width: 600px) {
              margin-left: 0;
            }
          }

          img {
            @media (max-width: 600px) {
              width: 80px;
              height: 80px;
            }
          }

          h4 {
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 143.5%;
            color: $color-gray-darkest;
            margin: 7px 0;
          }

          b {
            font-size: 17px;
            line-height: 20px;
            font-style: normal;
            font-weight: normal;
          }

          p {
            margin-top: 11px;
          }
        }
      }

      .block.block-before-steps {
        padding-top: 0;
        padding-bottom: 30px;
      }

      .block-steps {
        display: flex;
        justify-content: center;

        @media (max-width: 768px) {
          flex-direction: column;
          align-items: center;
        }

        .step-item {
          display: flex;
          flex-direction: column;
          max-width: 210px;

          @media (max-width: 768px) {
            max-width: 350px;
          }

          + .step-item {
            margin-left: 20px;
            @media (max-width: 768px) {
              margin-left: 0;
            }
          }

          b {
            font-family: $font-secondary;
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 36px;
            color: $color-brand-blue;
            border-bottom: 1px solid $color-gray-light;
            display: inline-block;
            padding-bottom: 14px;
          }

          span {
            display: inline-block;
            padding-top: 10px;
            font-family: $font-primary;
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 20px;
            font-feature-settings: 'kern' off;
            color: $color-black;
          }
        }
      }

      .block-5 {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 0 !important;
      }

      .block-6 {
        padding-bottom: 43px;
        p {
          margin-top: 33px;
        }
      }

      .how-look-test {
        .block {
          padding-bottom: 34px;
        }
      }

      .q-images {
        display: none;
        justify-content: center;

        &--b {
          display: flex;
        }

        &--s {
          img {
            max-width: 250px;
          }

          .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            border-radius: initial;
            background-color: $color-yellow-dark;
            opacity: 1;

            transition: all 0.2s ease-in-out;
          }

          .swiper-pagination-bullet-active {
            width: 16px !important;
            height: 16px !important;
            background-color: $color-yellow !important;
          }

          .swiper-wrapper {
            padding-bottom: 100px;
          }
          .swiper-slide {
            justify-content: center;
            align-items: center;
            display: flex;
          }
        }

        @media (max-width: 1400px) {
          &--b {
            display: none;
          }

          &--m {
            display: flex;
          }

          &--s {
            display: none;
          }
        }

        @media (max-width: 768px) {
          &--b {
            display: none;
          }

          &--m {
            display: none;
          }

          &--s {
            display: flex;
          }
        }

        img + img {
          padding-left: 20px;
        }
      }

      .res-block {
        padding-top: 30px;
        display: flex;

        @media (max-width: 768px) {
          flex-direction: column;
        }

        p {
          margin-top: 0;
        }

        .left {
          padding-right: 136px;

          @media (max-width: 768px) {
            margin: 0 auto;
            padding: 0 0 20px;
          }
        }

        .right {
          @media (max-width: 768px) {
            max-width: 430px;
            margin: 0 auto;

            & .landing-button {
              margin: 0 auto;
            }
          }
        }
      }

      .feedback {
        @media (max-width: 1200px) {
          max-width: 440px;
        }
      }
    }
  }

  .footer {
    width: 100%;
    height: 60px;
    background: $color-black;
    @media (max-width: 768px) {
      height: auto;
    }

    .block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 670px;
      margin: 0 auto;
      height: 100%;

      @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 20px 15px;
      }
    }

    a {
      color: $color-gray-light;
      text-decoration: none;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;

      @media (max-width: 768px) {
        display: inline-block;
        margin-bottom: 10px;
      }
    }
  }

  .input-group {
    position: relative;
    label {
      position: absolute;
      left: 10px;
      top: 0;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 50px;
      color: #181818;
    }

    input {
      background: #F0F0F0;
      height: 50px;
      max-width: 440px;
      color: $color-gray-darkest;
      border: none;
      border-radius: 4px;
      box-sizing: border-box;
      width: 100%;
      margin-bottom: 10px;
      padding-left: 100px;

      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 50px;
    }

    &--white {
      input {
        background: $color-white;
      }
    }
  }

  i.policy {
    display: block;
    margin-top: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    font-feature-settings: 'kern' off;
    color: $color-gray-light;

    a {
      color: $color-brand-blue;
      text-decoration: underline;
    }
  }
}
