.plumber {
	@include media-breakpoint-down(xs) {
		position: absolute;
		left: 15px;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;
	}
	&__container {
		position: relative;
		@include media-breakpoint-up(sm) {
			margin-left: auto;
			margin-right: auto;
			max-width: 600px;
		}
		@include media-breakpoint-down(xs) {
			margin-left: auto;
			margin-right: auto;
			max-width: 400px;
		}
	}
	&__gamearea {
		position: relative;
		&:before,
		&:after {
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: contain;
			content: '';
			display: block;
			padding-top: 16.666%;
			position: absolute;
			width: 16.666%;
		}
		&:before {
			background-image: url(../../assets/images/tube-start.svg);
			bottom: 100%;
			left: 0;
		}
		&:after {
			background-image: url(../../assets/images/tube-end.svg);
			right: 0;
			top: 100%;
		}
		.step--finished &:after,
		.step--break &:after {
			background-image: url(../../assets/images/tube-end-filled.svg);
		}
	}
	&__row {
		display: flex;
	}
	&__col {
		border: 1px solid $color-gray-light;
		height: 0;
		padding-top: 16.66%;
		position: relative;
		flex-grow: 1;
	}
	&__gui {
		color: white;
		display: flex;
		position: absolute;
		right: 0;
		top: -65px;
	}
	&__info {
		align-items: baseline;
		display: flex;
		@include media-breakpoint-up(sm) {
			margin-left: 60px;
		}
		@include media-breakpoint-down(xs) {
			margin-left: 20px;
		}
	}
	&__info-label {
		font-size: 17px;
		text-transform: uppercase;
	}
	&__info-value {
		font-family: $font-secondary;
		font-size: 24px;
		line-height: 1;
		text-align: right;
		@include media-breakpoint-up(sm) {
			margin-left: 40px;
			text-align: left;
			width: 60px;
		}
		@include media-breakpoint-down(xs) {
			width: 60px;
		}
	}

}