.lk-test-row {
  width: 100%;
  min-height: 100px;
  border: 1px solid $color-gray-light;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  padding: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }

  color: $color-black;

  * {
    box-sizing: border-box;
  }

  &--accent {
    border: 1px solid $color-brand-blue;
  }

  + .lk-test-row {
    margin-top: 20px;
  }

  .left {
    position: relative;
    flex: 1;
    padding-left: 30px;
    height: auto;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      margin: 0 auto 15px;
      min-width: 240px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      left: 0;
      top: 5px;
      background: url(./../../assets/images/child-white.svg) no-repeat;
    }

    .name {
      font-size: 24px;
      line-height: 28px;
    }

    .gender-age {
      margin-top: 12px;
      font-size: 17px;
      line-height: 20px;
    }
  }

  .center {
    flex: 1;
    margin-left: 20px;

    @media (max-width: 768px) {
      margin: 0 auto;
      min-width: 240px;
    }

    .test-status {
      font-size: 24px;
      line-height: 28px;
    }

    .step-status {
      width: 100%;
      height: 4px;
      display: flex;
      margin-top: 23px;

      .step-block {
        width: 12px;
        height: 4px;
        background: $color-gray-light;

        &--finished {
          background: $color-brand-blue;
        }

        & + .step-block {
          margin-left: 7px;
        }
      }
    }
  }

  .right {
    width: 250px;
    min-width: 250px;
    margin-left: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 768px) {
      min-width: 240px;
      margin: 15px auto 0;
    }

    .button {
      width: 250px;
    }

    .result-status {
      font-size: 24px;
      line-height: 28px;
    }

    .result-timer {
      margin-top: 12px;
      font-size: 17px;
      line-height: 20px;
    }
  }
}
