.step-target {
	&--name {
		.step-target__wrapper {
			flex-direction: row-reverse;

			.step-target__icon {
				margin-right: 0;
				margin-left: 10px;
			}
		}

		.step-target__text {
			font-size: 17px;
			line-height: 20px;
			text-transform: none;

			@media (max-width: 400px) {
				max-width: 120px;
			}
		}
	}

	&__wrapper {
		display: flex;
		align-items: center;
	}
	&__icon {
		border: 1px solid $color-gray;
		border-radius: 50%;
		height: 20px;
		margin-right: 5px;
		width: 20px;
	}
	&--child &__icon {
		background-image: url(../../assets/images/target-child.svg);
		background-position: 50% 100%;
		background-repeat: no-repeat;
		background-size: 8px auto;
	}
	&--adult &__icon {
		background-image: url(../../assets/images/target-adult.svg);
		background-position: 50% 100%;
		background-repeat: no-repeat;
		background-size: 8px auto;
	}
	&__text {
		max-width: 200px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: $color-gray;
		font-size: 10px;
		line-height: 12px;
		text-transform: uppercase;
	}
}
