.panel-picker {
	&__list {

	}
	&__item {
		background: $color-brand-blue;
		color: $color-white;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		font-family: $font-primary;
		height: 80px;
		justify-content: center;
		margin-bottom: 10px;
		padding-left: 18px;
		padding-right: 18px;
		position: relative;
		border-radius: 4px;
		&::before {
			content: "";
			display: block;
			border-radius: 2px;
			border: 1px solid $color-white;
			width: calc(100% - 4px);
			height: calc(100% - 4px);
			position: absolute;
			left: 2px;
			top: 2px;
			box-sizing: border-box;
			pointer-events: none;
		}
	}
	&__title {
		font-size: 17px;
	}
	&__text {
		font-size: 12px;
	}
}
