.haregame {
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: 960px;
	&__list {
		height: 100%;
	}
	&__item {
		float: left;
		margin-left: 16.6%;
		width: 16.6%;


		// 50vh - 200px
		@include media-breakpoint-up(md) {
			margin-bottom: calc((50vh - 200px)/2);
			margin-bottom: calc((50 * var(--vh) - 200px)/2);
			@media (max-height: 700px) {
				margin-bottom: 75px;
			}
		}
		@include media-breakpoint-only(sm) {
			margin-bottom: calc((50vh - 310px)/2);
			margin-bottom: calc((50 * var(--vh) - 310px)/2);
			@media (max-height: 700px) {
				margin-bottom: 20px;
			}
		}
		@include media-breakpoint-down(xs) {
			margin-bottom: calc((50vh - 170px)/2);
			margin-bottom: calc((50 * var(--vh) - 170px)/2);
			 
			@media (max-height: 480px) {
				margin-bottom: 35px;
			}
		}
		&:nth-of-type(6n + 1) {
			margin-left: 0;
		}
	}
}