.countdown {
  background: black;
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100vw;
  min-height: 100%;
  width: initial !important;
  height: initial !important;
  transform: translateY(-50%);
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  z-index: 200;

  padding-right: 510px;

  @include media-breakpoint-down(sm) {
    padding-right: 0;
  }

  &-exiting {
    transition: opacity 5s ease-in-out;
    opacity: 0;
  }

  &-exited {
    transition: opacity 5s ease-in-out;
    opacity: 0;
    visibility: hidden;
  }

  //'entering'
  //'entered'
  //'exiting'
  //'exited'

  .number {
    font-size: 40px;
    color: #ffffff;
    position: absolute;
    width: 100px;
    height: 100px;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    > div {
      width: 100px;
      height: 100px;
    }
  }

  .fade-number {
    transform-origin: center;
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    &-entering {
      opacity: 1;
      transform: scale(5);
      transition: all 0.5s ease-in-out;
    }

    &-entered {
      opacity: 0;
      transform: scale(5);
      transition: all 0.5s ease-in-out;
    }

    &-exiting {
      opacity: 0;
      transform: scale(1);
      transition: all 0.1s ease-in-out;
    }

    &-exited {
      opacity: 0;
      transform: scale(1);
      transition: none;
    }
  }
}
