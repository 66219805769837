.harehole {
	position: relative;
	&__hole {
		border-radius: 100%;
		background: black;
		height: 40px;
		@include media-breakpoint-up(sm) {
			height: 40px;
		}
		@include media-breakpoint-down(xs) {
			height: 20px;
		}
	}
	&__hare {
		background-image: url(../../assets/images/rabbit-2.png);
		bottom: 10px;
		position: absolute;
		height: 0;
		opacity: 0;
		left: 0;
		right: 0;
		transition: 0s background-image, padding-top 1.5s, 0s opacity;
		background-size: 100% auto;
	}
	&--clicked &__hare {
		transition: 0s background-image, 1s padding-top 2s;
		padding-top: 66%;
		// height: 80px;
	}
	&--active &__hare {
		background-image: url(../../assets/images/rabbit-1.png);
		padding-top: 138%;
		// height: 180px;
	}



	.step--playing &__hare {
		opacity: 1;
	}
}
