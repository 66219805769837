.field {
	&__label {
		display: block;
		font-size: 17px;
		line-height: 20px;
		margin-bottom: 10px;
		&--white {
			color: white;
		}
	}

	&--row {
		display: flex;
	}
}
