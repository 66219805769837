// Declare your font-family/families here

@font-face {
	font-family: 'Cuprum';
	src: url('../assets/fonts/Cuprum-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Cuprum';
	src: url('../assets/fonts/Cuprum-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}
//@font-face {
//	font-family: 'Druk';
//	src: url('../assets/fonts/DrukCyr-Medium.ttf') format('truetype');
//	font-weight: normal;
//	font-style: normal;
//}

//@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');