.panel {
	background: $color-white;
	z-index: 30;
	@include media-breakpoint-down(xs) {
		background: rgba(255, 255, 255, 0.8);
	}
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.07);
	box-sizing: border-box;
	color: $color-black;
	@include media-breakpoint-up(md) {
		padding: 40px;
	}
	@include media-breakpoint-only(sm) {
		padding-top: 20px;
		padding-bottom: 20px;
	}
	@include media-breakpoint-down(xs) {
		padding-bottom: 40px;
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 20px;
	}
	&--reaction,
	&--cns,
	&--breath {
		position: absolute;
		z-index: 12;
		@include media-breakpoint-up(md) {
			min-height: calc(100vh - 80px);
			padding-bottom: 160px;
			top: 40px;
			right: 40px;
			width: 440px;
			@media (max-height: 700px) {
				bottom: 40px;
			}
		}
		@include media-breakpoint-only(sm) {
			bottom: 30px;
			left: 30px;
			right: 30px;
		}
		@include media-breakpoint-down(xs) {
			bottom: 0;
			left: 0;
			right: 0;
		}
	}
	&--jump {
		@include media-breakpoint-up(md) {
			min-height: calc(100vh - 80px);
			padding-bottom: 160px;
			position: absolute;
			top: 40px;
			right: 40px;
			width: 440px;
			@media (max-height: 700px) {
				bottom: 40px;
			}
		}
		@include media-breakpoint-only(sm) {
			bottom: 30px;
			left: 30px;
			padding-bottom: 80px;
			position: relative;
			right: 30px;
		}
		@include media-breakpoint-down(xs) {
			padding-top: 70px;
		}
	}
	&__wrapper {
		@include media-breakpoint-only(sm) {
			margin-left: auto;
			margin-right: auto;
			width: 510px;
		}
	}

	&__title {
		color: $color-brand-blue;
		font-family: $font-secondary;
		font-size: 36px;
		line-height: 1;
	}

	&__text {
		font-size: $font-size-default;
		line-height: $line-height-default;
		&--highlighted {
			font-weight: bold;
		}
		&--finished {
			display: none;
			@include media-breakpoint-up(md) {
				margin-top: 40px;
			}
			@include media-breakpoint-down(sm) {
				margin-top: 20px;
			}
		}
	}
	&__title,
	&__text {
		&:nth-last-child(n + 2) {
			margin-bottom: 20px;
		}
	}

	&__dots {
		align-items: baseline;
		border-bottom: 1px solid $color-gray;
		display: flex;
		padding-bottom: 10px;
		@include media-breakpoint-up(md) {
			margin-top: 40px;
		}
		@include media-breakpoint-down(sm) {
			margin-top: 20px;
		}
	}
	&__dots-label {
		text-transform: uppercase;
	}
	&__dots-component {
		margin-left: 80px;
	}

	&__result {
		align-items: baseline;
		border-bottom: 1px solid $color-gray;
		display: flex;
		padding-bottom: 10px;
		justify-content: space-between;
		@include media-breakpoint-up(md) {
			margin-bottom: 40px;
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: 20px;
		}
	}
	&__result-label {
		text-transform: uppercase;
	}
	&__result-value {
		font-family: $font-secondary;
		font-size: 24px;
	}

	&__field {
		max-width: 360px;
	}

	&__footer {
		@include media-breakpoint-up(md) {
			bottom: 40px;
			left: 40px;
			position: absolute;
			right: 40px;
		}
		@include media-breakpoint-down(sm) {
			margin-top: 25px;
		}
	}

	.step--clean &__footer {
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
	.step--clean &__dots {
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	.step--playing &__title {
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
	.step--playing &__text {
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
	.step--playing &__button-wrapper {
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	.step--playing.step--coordination &__button-wrapper,
	.step--playing.step--breath &__button-wrapper {
		@include media-breakpoint-down(sm) {
			display: block;
			margin-bottom: 10px;
		}
	}

	.step--playing &__footer {
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
	.step--playing &__dots {
		@include media-breakpoint-down(sm) {
			margin-top: 0;
		}
	}


	.step--break &__title {
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
	.step--break &__text {
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
	.step--break &__footer &__button-wrapper {
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}



	.step--finished &__title {
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
	.step--finished &__text {
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
	.step--finished &__body &__button-wrapper {
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
	.step--finished &__text--finished {
		display: block;
	}





	&--jump &__jump-example {
		@include media-breakpoint-up(md) {
			bottom: 0;
			position: absolute;
			right: 100%;
			top: 0;
			width: calc(100vw - 540px);
		}
		@include media-breakpoint-only(sm) {
			bottom: 100%;
			height: 360px;
			left: 0px;
			position: absolute;
			right: 0px;
			top: auto;
		}
		@include media-breakpoint-down(xs) {
			margin-bottom: 40px;
			margin-left: -30px;
			margin-right: -30px;
			margin-top: 30px;
			position: relative;
		}
	}
	&--jump &__jump-video {
		padding-top: 36.75%;
		@include media-breakpoint-up(sm) {
			left: 0;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
		@include media-breakpoint-down(xs) {
			position: relative;
		}
	}

	&--jump &__jump-player {
		left: 0;
		position: absolute;
		top: 0;
	}
}
