.two-medoed-block {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 140px;
  padding-right: 500px;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    padding-right: 0;
    padding-top: 60px;
  }

  .medoed {
    position: relative;
    width: 100%;

    img {
      overflow: hidden;
      width: auto;
      object-fit: contain;
      position: absolute;
      left: 0;
      top: 0;

      bottom: 0;
      right: 0;

      height: calc(0.7 * 100vh);
      margin: 0 auto;

      @include media-breakpoint-down(sm) {
        height: calc(0.7 * 100vw);
      }
    }

    .body {
      z-index: 10;
    }

    .hand {
      transform: rotate(73deg);
      transform-origin: 25% 28%;
      transition: transform 0.5s ease-in-out;

      &--left {
        z-index: 11;
      }

      &--right {
        z-index: 9;
      }
    }
  }

  &--hands-up {
    .medoed {
      .hand {
        transform: rotate(0);
      }
    }
  }
}
