.step {
  height: 100%;
  position: absolute;
  width: 100%;


  &--in-countdown-state {
    @include media-breakpoint-down(sm) {
      .panel {
        display: none !important;
      }
    }
  }

  &--reaction {
    background-image: url(../../assets/images/rabbit-bg.png);
    background-size: cover;
    background-position: 50% 50%;
    @include media-breakpoint-up(md) {
      min-height: 700px;
    }
    @include media-breakpoint-only(sm) {
      min-height: 700px;
    }
    @include media-breakpoint-down(xs) {
      min-height: 480px;
    }
  }

  &--cns {
    background-image: url(../../assets/images/pool-bg.png);
    background-size: cover;
    background-position: 50% 50%;
    @include media-breakpoint-up(md) {
      min-height: 700px;
    }
    @include media-breakpoint-only(sm) {
      min-height: 700px;
    }
  }

  &--coordination {
    background-color: #F3EBD6;
    background-image: url(../../assets/images/zal-bg.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;
    @include media-breakpoint-up(md) {
      min-height: 700px;
    }
    @include media-breakpoint-only(sm) {
      min-height: 700px;
    }

    .step__playground {
      width: 100%;
      height: 100%;
    }
  }

  &--breath {
    background-color: #F3EBD6;

    @include media-breakpoint-up(md) {
      min-height: 700px;
    }
    @include media-breakpoint-only(sm) {
      min-height: 700px;
    }

    .step__playground {
      width: 100%;
      height: 100%;
    }
  }

  &__wolf {
    background-image: url(../../assets/images/wolf.png);
    background-position: 0 50%;
    background-size: cover;
    position: absolute;
    @include media-breakpoint-up(md) {
      height: 360px;
      right: 447px;
      top: 140px;
      width: 191px;
    }
    @include media-breakpoint-only(sm) {
      height: 280px;
      right: 0;
      top: 90px;
      width: 122px;
    }
    @include media-breakpoint-down(xs) {
      height: 172px;
      right: 0;
      top: 45px;
      width: 76px;
    }
  }

  &__beaver {
    background-image: url(../../assets/images/beaver.png);
    background-position: 0 100%;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    @include media-breakpoint-up(md) {
      left: 0px;
      height: 300px;
      right: 540px;
      top: -300px;
      width: calc(100vw - 540px);
    }
    @include media-breakpoint-only(sm) {
      height: 280px;
      left: -30px;
      top: -280px;
      width: 100%;
    }
    @include media-breakpoint-down(xs) {
      height: 172px;
      right: 0;
      top: 45px;
      width: 76px;
    }
  }

  &__playground {
    // background: rgba(red, 0.5);
  }

  &__step-nav {
    position: absolute;
    z-index: 20;
    @include media-breakpoint-up(md) {
      bottom: 40px;
      left: 40px;
      right: 500px;
    }
    @include media-breakpoint-only(sm) {
      bottom: 10px;
      left: 50%;
      margin-left: -255px;
      width: 510px;
    }
    @include media-breakpoint-down(xs) {
      bottom: 15px;
      left: 15px;
      right: 15px;
    }
  }

  &--jump {
    background: $color-white;;
    height: auto;
    .step__step-nav {

      @include media-breakpoint-up(md) {
        width: 810px;
        bottom: 20px;
        left: 50%;
        margin-left: -405px;
      }
      @include media-breakpoint-only(sm) {
        bottom: 10px;
        left: 50%;
        margin-left: -255px;
        width: 510px;
      }
      @include media-breakpoint-down(xs) {
        bottom: 15px;
        left: 15px;
        right: 15px;
      }
    }

  }

  &--reaction &__playground {
    position: absolute;
    top: 50vh;
    top: calc(50 * var(--vh));
    @include media-breakpoint-up(md) {
      bottom: 80px;
      left: 40px;
      right: 500px;
      @media (max-height: 700px) {
        top: 350px;
      }
    }
    @include media-breakpoint-only(sm) {
      bottom: 190px;
      left: 30px;
      right: 30px;
      @media (max-height: 700px) {
        top: 350px;
      }
    }
    @include media-breakpoint-down(xs) {
      bottom: 110px;
      left: 20px;
      right: 20px;
      @media (max-height: 480px) {
        top: 240px;
      }
    }
  }

  &--cns &__playground {
    background: $color-gray-darkest;
    position: absolute;
    // top: 50vh;
    // top: calc(50 * var(--vh));
    @include media-breakpoint-up(md) {
      bottom: 80px;
      left: 40px;
      padding-bottom: 100px;
      padding-top: 100px;
      right: 500px;
      top: 340px;
      top: auto;
      @media (max-height: 700px) {
      }
    }
    @include media-breakpoint-only(sm) {
      bottom: 150px;
      left: 30px;
      padding-bottom: 100px;
      padding-top: 100px;
      right: 30px;
    }
    @include media-breakpoint-down(xs) {
      bottom: 0;
      left: 0px;
      right: 0px;
      padding-bottom: 110px;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 110px;
      top: 0;
    }
  }

  &--cns.step--clean &__playground {
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  &--finished &__beaver,
  &--playing &__beaver,
  &--break &__beaver {
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  &--finished.step--coordination,
  &--finished.step--breath,
  {
    .panel__result.panel__result--current,
    .panel__dots {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  .panel__body {
    display: flex;
    flex-direction: column;
  }

  &--breath {
    overflow: hidden;
  }

  &--coordination,
  &--breath {
    display: flex;
    flex-direction: column;

    .panel__body {
      .panel__button-wrapper {
        @include media-breakpoint-down(sm) {
          order: 0;
        }
      }
    }

    .panel__dots {
      @include media-breakpoint-down(sm) {
        order: 1;
      }
    }

    .panel__result.panel__result--current {
      margin-top: 10px;
      @include media-breakpoint-down(sm) {
        order: 2;
        margin-bottom: 0;
      }
    }

    &.step--playing {
      .panel__result.panel__result--current {
        @include media-breakpoint-down(sm) {
          order: -100;
          margin-bottom: 20px;
        }
      }

      .panel__body {
        .panel__button-wrapper {
          @include media-breakpoint-down(sm) {
            margin-bottom: 20px;
          }
        }
      }
    }

    &.step--break {
      .panel__result.panel__result--current {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      .panel__body {
        .panel__button-wrapper {
          @include media-breakpoint-down(sm) {
            margin-bottom: 20px;
          }
        }
      }
    }

    &.step--clean {
      .panel__body {
        .panel__result,
        .panel__dots {
          display: none;
        }
      }

      .panel__footer {
        .panel__result {
          display: none;
        }
      }
    }

    &.step--finished {
      .panel__result.panel__result--current,
      .panel__body .panel__button-wrapper {
        display: none;
      }

      .panel__dots {
        & > div {
          display: none;
        }
      }
    }
  }
}
